import React, { useState } from "react";
import Logo from "../assets/images/logo.svg"
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BiMenuAltRight } from "react-icons/bi";

function Header() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <header>
                <div className="container">
                    <div className="header-in">
                        <div className="row justify-content-between">
                            <figure className="col-sm-3">
                                <img src={Logo} alt="" />
                            </figure>
                            <div className="menu col-md-9">
                                <ul className="desk-menu">
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#about">About Us</a>
                                    </li>
                                    <li>
                                        <a href="#contact">Contact Us</a>
                                    </li>
                                </ul>

                                <Button variant="primary" onClick={handleShow}>
                                    <BiMenuAltRight />
                                </Button>

                                <Offcanvas show={show} onHide={handleClose}>
                                    <Offcanvas.Header closeButton>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <ul className="mobil-menu">
                                            <li>
                                                <a href="#home" smooth={true} duration={500}>Home</a>
                                            </li>
                                            <li>
                                                <a href="#about" smooth={true} duration={500}>About Us</a>
                                            </li>
                                            <li>
                                                <a href="#contact" smooth={true} duration={500}>Contact Us</a>
                                            </li>
                                        </ul>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
