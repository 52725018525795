import React from 'react';
import './Home.css';
import MainBanner from "../assets/images/banner-image.avif"
import BenefitsImg from "../assets/images/benefits-image.avif"
import Button from 'react-bootstrap/Button';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import Header from './Header';

const events = [
    { title: 'Event 1', date: '2024-12-01', description: 'Description for event 1.' },
    { title: 'Event 2', date: '2024-12-02', description: 'Description for event 2.' },
    { title: 'Event 3', date: '2024-12-03', description: 'Description for event 3.' },
];
function Home() {

    return (

        <section className='main-body'>
            <Header />
            <section className='main-banner' id='home'>
                <div className='main-banner-in'>
                    <div className='container'>
                        <div className='row'>
                            <aside className='banner-left col-lg-6 col-md-12'>
                                <h2 className='title'>Gigllee: Connecting Artists<br /> and Venues for <br />Unforgettable Gigs</h2>
                                <p className='content'>Gigllee is a platform that makes booking gigs simple and seamless for artists and venues.</p>
                            </aside>
                            <aside className='banner-right col-lg-6 col-md-12'>
                                <figure>
                                    <img src={MainBanner} alt='' />
                                </figure>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <section className='about-sec' id='about'>
                <div className='container'>
                    <div className='content-box'>
                        <h2 className='title'>About Gigllee: Revolutionizing the Live Music Industry</h2>
                        <div className='about-grid row'>
                            <aside className='about-block col-md-6'>
                                <h3>Simplified Booking</h3>
                                <p className='content'>Gigllee simplifies the gig booking process with an intuitive online platform, eliminating the need for time-consuming phone calls and emails.</p>
                            </aside>
                            <aside className='about-block col-md-6'>
                                <h3>Wider Reach</h3>
                                <p className='content'>Artists can reach a broader audience by showcasing their talents to a wider range of venues, increasing their booking opportunities.</p>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <section className='feature-sec'>
                <div className='container'>
                    <h2 className='title'>Features: Streamlining the Gig-Booking Process</h2>
                    <div className='row'>
                        <aside className='feature-step col-md-6'>
                            <span className='number'>1</span>
                            <div className='feature-cont'>
                                <h3>Artist Profiles</h3>
                                <p className='content'>Artists can create detailed profiles with their music, videos, and contact information.</p>
                            </div>
                        </aside>
                        <aside className='feature-step col-md-6'>
                            <span className='number'>2</span>
                            <div className='feature-cont'>
                                <h3>Venue Listings</h3>
                                <p className='content'>Venues can list their available dates, capacity, and performance space details.</p>
                            </div>
                        </aside>
                        <aside className='feature-step col-md-6'>
                            <span className='number'>3</span>
                            <div className='feature-cont'>
                                <h3>Secure Messaging</h3>
                                <p className='content'>Gigllee provides a secure messaging platform for communication between artists and venues.</p>
                            </div>
                        </aside>
                        <aside className='feature-step col-md-6'>
                            <span className='number'>4</span>
                            <div className='feature-cont'>
                                <h3>Easy Booking</h3>
                                <p className='content'>The platform allows artists and venues to easily schedule and confirm gigs.</p>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            <section className='benefits-sec'>
                <div className='container'>
                    <div className='row'>
                        <aside className='benefits-sec-left col-xl-7 col-lg-12'>
                            <h2 className='title'>Benefits for Artists: Expand Your Reach and Grow Your Fan Base</h2>
                            <div className='row'>
                                <aside className='benefits-block col-md-6'>
                                    <div className='benefits-block-in'>
                                        <h3>Increased Visibility</h3>
                                        <p className='content'>Artists can reach a larger audience through Gigllee's extensive network of venues.</p>
                                    </div>
                                </aside>
                                <aside className='benefits-block col-md-6'>
                                    <div className='benefits-block-in'>
                                        <h3>More Booking Opportunities</h3>
                                        <p className='content'>Gigllee provides a convenient platform for artists to secure more gigs and expand their performance schedule.</p>
                                    </div>
                                </aside>
                                <aside className='benefits-block col-md-6'>
                                    <div className='benefits-block-in'>
                                        <h3>Fan Engagement</h3>
                                        <p className='content'>Artists can engage with their fans directly through Gigllee's messaging features.</p>
                                    </div>
                                </aside>
                                <aside className='benefits-block col-md-6'>
                                    <div className='benefits-block-in'>
                                        <h3>Career Growth</h3>
                                        <p className='content'>Gigllee helps artists build their career by providing them with opportunities to perform in new venues and connect with new audiences.</p>
                                    </div>
                                </aside>
                            </div>
                        </aside>
                        <aside className='benefits-sec-right col-xl-5 col-lg-12'>
                            <figure>
                                <img src={BenefitsImg} alt='' />
                            </figure>
                        </aside>
                    </div>
                </div>
            </section>

            <section className='about-sec discover-sec'>
                <div className='container'>
                    <div className='content-box'>
                        <h2 className='title'>Benefits for Venues: Discover Talented Acts and Fill Your Calendar</h2>
                        <div className='about-grid row'>
                            <aside className='about-block col-xl-3 col-lg-6'>
                                <h3>Find New Talent</h3>
                                <p className='content'>Venues can discover new and exciting artists based on their genre preferences and venue capacity.</p>
                            </aside>
                            <aside className='about-block col-xl-3 col-lg-6'>
                                <h3>Fill Open Dates</h3>
                                <p className='content'>Gigllee helps venues fill their calendar with talented artists, maximizing their revenue and attracting new customers.</p>
                            </aside>
                            <aside className='about-block col-xl-3 col-lg-6'>
                                <h3>Streamlined Communication</h3>
                                <p className='content'>The secure messaging platform allows venues to easily communicate with artists, schedule gigs, and manage their bookings.</p>
                            </aside>
                            <aside className='about-block col-xl-3 col-lg-6'>
                                <h3>Promote Events</h3>
                                <p className='content'>Venues can promote their upcoming gigs through Gigllee's platform, reaching a wider audience and generating more interest.</p>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <section className='success-stories-sec'>
                <div className='container'>
                    <h2 className='title'>Success Stories: Hear from Our Satisfied Users</h2>
                    <div className='success-stories-grid row'>
                        <aside className='success-stories-block col-lg-6 col-md-12'>
                            <div className='success-stories-block-in'>
                                <p className='content'>“Gigllee has been a game changer for my band. We've found amazing new venues and have been able to book more gigs than ever before.” <strong>- John Smith, Lead Singer of The Bluebirds</strong></p>
                                <span className='number'>1</span>
                            </div>
                        </aside>
                        <aside className='success-stories-block col-lg-6 col-md-12'>
                            <div className='success-stories-block-in'>
                                <p className='content'>"I highly recommend Gigllee to any artist looking to expand their reach and connect with new venues. It's a must-have tool for any musician." <strong>- Emily Brown, Solo Musician</strong></p>
                                <span className='number'>3</span>
                            </div>
                        </aside>
                    </div>
                    <div className='success-stories-grid row justify-content-center last-success-block'>
                        <aside className='success-stories-block col-lg-6 col-md-12'>
                            <div className='success-stories-block-in'>
                                <span className='number'>2</span>
                                <p className='content'>"Gigllee has made it so easy to find talented artists and fill our calendar. The platform is user-friendly and efficient." <strong>- Sarah Jones, Owner of The Jazz Cafe</strong></p>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>


            <section className='pricing-sec'>
                <div className='container'>
                    <h2 className='title'>Pricing and Plans: Find the Right Fit for Your Needs</h2>
                    <div className='row'>
                        <aside className='col-md-4'>
                            <div className="pyramid">
                                <div className="pyramid__section"><span>1</span></div>
                                <div className="pyramid__section"><span>2</span></div>
                                <div className="pyramid__section"><span>3</span></div>
                            </div>
                        </aside>
                        <aside className='col-md-8'>
                            <div className='pyramid-cont-block'>
                                <div className='pyramid-cont'>
                                    <h2>Free Plan</h2>
                                    <p>Basic features, limited access.</p>
                                </div>
                                <div className='pyramid-cont'>
                                    <h2>Pro Plan</h2>
                                    <p>Advanced features, increased visibility.</p>
                                </div>
                                <div className='pyramid-cont'>
                                    <h2>Premium Plan</h2>
                                    <p>Exclusive features, maximum reach.</p>
                                </div>
                            </div>
                        </aside>
                    </div>


                </div>
            </section>


            <section className='about-sec get-started' id='contact'>
                <div className='container'>
                    <div className='content-box'>
                        <h2 className='title'>Get Started: Download Gigllee and Book Your Next Gig Today</h2>
                        <div className='buttons-grid'>
                            <Button variant="primary" disabled>Download Gigllee</Button>
                            <Button variant="primary">Contact Us</Button>
                        </div>
                    </div>
                </div>
            </section>

        </section>

    );
}

export default Home;
